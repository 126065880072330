import React from "react";
import "./App.css";
import MainBody from "./components/MainBody";

function App() {
  return (
    <div className="App">
      <MainBody/>
    </div>
  );
}
export default App;
